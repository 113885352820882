.container {
  h2 {
    text-align: center;
  }
  .cards {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
