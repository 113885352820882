.layout {
  max-width: 480px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.headerWrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 480px;
  background: #fff;
  z-index: 9;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 16px;
    border-bottom: 1px solid #ececec;

    &.withUnderHeader {
      border-bottom: none;
    }

    .headerItem {
      width: 30px;
    }
    .backBtn {
      outline: 0;
      border: 0;
      background: transparent;
    }
    .logo {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -1px;
      text-align: center;
      width: 250px;
    }
  }

  .navigation {
    z-index: 5;
    display: none;
    transition: opacity 0.4s ease;

    &.open {
      display: flex;
      opacity: 1;
    }

    &.close {
      opacity: 0;
      display: none !important;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #ffffff1a;
      z-index: 5;
      height: 54px;
    }

    position: absolute;
    transition: opacity 0.4s ease;
    background-color: #000000e5;
    backdrop-filter: blur(8px);
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    ul {
      display: flex;
      list-style: none;
      align-items: center;
      gap: 24px;
      padding: 0;
      margin: 0;
      padding: 0 16px;
      margin-top: 154px !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      width: 100%;
      li {
        text-align: center;

        a {
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap;
          font-weight: 400 !important;
          font-size: 32px !important;
        }
      }
    }
    button {
      background-color: #fff;
      color: black;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .loginNav {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 48px;
    }
    .contact {
      color: #fff;

      margin-top: auto !important;
      display: block !important;
      text-align: center;
      font-size: 16px;
      padding: 16px;
      font-weight: 500;
      padding-bottom: 40px;
      a {
        color: var(--main-pink);
      }
    }
  }
}

.main {
  height: 100%;
  padding: 24px 20px;
  padding-top: 78px;

  .withUnderHeader + & {
    padding-top: 108px;
  }
}

.burger {
  width: 24px;
  height: 18px;
  position: relative;
  z-index: 6;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &.open {
    span {
      background-color: #fff;
    }
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

.burger span:nth-child(1) {
  top: 0px;
}

.burger span:nth-child(2),
.burger span:nth-child(3) {
  top: 6px;
}

.burger span:nth-child(4) {
  top: 12px;
}

.burger.open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

.burger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
