.card {
  padding: 24px 20px;
  border-radius: 16px;
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    h6 {
      font-size: 14px !important;
      font-weight: 500 !important;
      margin: 0 !important;
    }
  }
  .paragraph {
    font-size: 13px;
    margin-top: 8px;
  }
  &.Normal {
    background-color: #e7f5f1;
  }
  &.Overweight {
    background-color: #f9f7ec;
  }
  &.Obese {
    background-color: #f5e7e7;
  }
  &.Underweight {
    background-color: #e7f2f5;
  }
}
