.progressBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 16px !important;
  }
}

.progressBars {
  display: flex;
  align-items: center;
  gap: 4px;
  .progressBarItem {
    height: 4px;
    width: 29px;
    border-radius: 9999px;
    background-color: #f1f1f1;
    overflow: hidden;
    .fill {
      height: 100%;
      width: 0;
      background-color: #000;
      transition: 0.4s ease;
    }
  }
}
