.advicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 4.3rem;
  .adviceVideo {
    width: 29.1rem;
    height: 29.1rem;
    border-radius: 20px;
  }
  .adviceTitle {
    margin-top: 1.8rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #000000;
  }
  .advicesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.8rem;
    .adviceItem {
      padding: 1rem;
      margin-bottom: 1.6rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: #f5f4f1;
      border-radius: 10px;
      .adviceIndex {
        margin-right: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3.7rem;
        min-height: 3.7rem;
        background: var(--primary);
        border-radius: 5px;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #ffffff;
      }
      .adviceName {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #7d7d7d;
      }
    }
  }
}
