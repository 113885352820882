.journey {
  text-align: center;
  padding: 25px 32px;
  background-color: #f6f7f9;
  margin-bottom: 30px;
  p {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 12px;

    img {
      opacity: 0.8;
    }
  }
}
