.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;

  .content {
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    line-height: 38px;
    span {
      color: #7c8af7;
    }
  }

  .decline {
    border: none;
    background: #fff;
    margin-top: 30px;
    p {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.41px;
    }
  }
}
