.header {
  width: 100%;
  height: 6.4rem;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 6.8rem);
    font-family: "Inter-600";
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.header_body {
  height: 100%;
  display: flex;
}
.header_button {
  width: 3.4rem;
  height: 100%;
  border: none;
  outline: none;
  background-color: white;
  img {
    width: 1.4rem;
  }
}

.progress_bar {
  width: 100%;
  background-color: #e4e4e4;
  height: 4px;
  border-radius: 3px;
}

.progress_bar_fill {
  display: block;
  height: 4px;
  background-color: #000;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.chose_button {
  border: none;
  outline: none;
  background: var(--white);
  border-radius: 20px;
  width: calc(100% - 4.8rem);
  height: 7.1rem;
  z-index: 2;
  font-family: "Inter-400";
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--black);
}

.chose_button__focus {
  background: var(--primary);
  color: var(--white);
}

.next_button {
  position: fixed;
  bottom: 0;
  border: none;
  outline: none;
  border-radius: 20px;
  width: 100%;
  margin: 1.6rem 2.4rem;
  height: 5.5rem;
  z-index: 2;
  font-family: "Inter-400";
  font-size: 1.8rem;
  line-height: 2.2rem;
  background: var(--primary);
  color: var(--white);
}

.next_button:disabled {
  background-color: var(--disabled-button-color);
}

.next_button:active {
  background-color: var(--disabled-button-color);
}

.title {
  width: 26.7rem;
  font-family: "Inter-600";
  font-style: normal;
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  color: var(--black);
}

.chose_button__questions {
  z-index: 2;
  border: none;
  outline: none;
  background: var(--color-button);
  border-radius: 20px;
  width: 100%;
  height: 6.7rem;
  font-family: "Inter-400";
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--black);
}

.chose_button__questions__focus {
  background: var(--primary);
  color: var(--white);
}

.statement_title {
  margin-bottom: 1.8rem;
  width: 26.7rem;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: var(--black);
}

.chose_button__tone {
  outline: none;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--black);
  padding: 2rem 0;
  width: 15.7rem;
  height: 16rem;
  border: 1px solid var(--button-image--border);
  border-radius: 2rem;

  img {
    width: 7.7rem;
  }

  p {
    font-family: "Inter-500";
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.chose_button__tone__focus {
  border: 2px solid var(--primary);
  background: var(--button-image--background);
}

.chose_button__problems {
  outline: none;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--black);
  padding: 2rem 0;
  width: 15.7rem;
  height: 18.2rem;
  border: 1px solid var(--button-image--border);
  border-radius: 2rem;
  position: relative;
  img {
    width: 7.7rem;
    margin-bottom: 2.1rem;
  }

  div {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 1.6rem;
    height: 1.6rem;
    background: var(--color-area);
    border-radius: 2px;
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  p {
    font-family: "Inter-500";
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.chose_button__problems__focus {
  border: 2px solid var(--primary);
  background: var(--button-image--background);
}


.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .modalWindow {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2.5rem 2rem 3.2rem 2rem;
      width: 30rem;
      background: #FFFFFF;
      border-radius: 20px;
  }
  .modalForm {
      margin-top: 2.2rem;
  }
  .modalTitle {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: #000000;
  }
  .modalDescription {
      margin-top: 1.3rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: center;
      color: #000000;
  }
  .modalSubmitButton {
      border: 0px;
      margin-top: 2.2rem;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 100%;
      height: 7.3rem;
      background: var(--primary);
      border-radius: 20px;
  }
  .closeModal {
      cursor: pointer;
      position: absolute;
      top: -4rem;
      right: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 50%;
      background-color: #FFFFFF;
  }
}

.errorMessage {
  margin-top: 0.5rem;
  align-self: flex-end;
  text-align: right;
  color: #FF0000;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.errorInput {
  border: 1px solid #FF0000;
}
//////////////////////////////////////////////////// 10
.chose_button__priority {
  div {
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //???
  padding-left: 2.3rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  background: var(--white);
  box-sizing: border-box;
  width: 17.6rem;
  height: 6.7rem;
  border: 1px solid var(--primary);
  border-radius: 2rem;
  color: var(--black);
  font-family: "Inter-500";
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.area_checkbox {
  width: 1.6rem;
  height: 1.6rem;
  background: var(--color-area);
  border-radius: 2px;
}

.priority_title {
  width: 100%;
  text-align: center;
}
//////////////////////////////////////////////////// 10



.submitButton {
  cursor: pointer;
  width: 100%;
  margin-top: 5rem;
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  border-radius: 20px;
  border: 0px;
  color: white;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.submitButtonLoading {
  background: #C8A4A3;
}

.errorMessage {
  margin-top: 0.5rem;
  align-self: flex-end;
  text-align: right;
  color: #FF0000;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.dotFlashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0s;
}

.dotFlashing::before, .dotFlashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dotFlashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  color:#ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: -0.5s;
}
.dotFlashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba( 255,255,255, 0.2);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dot-flashing {
  0% {
    background-color: rgba( 255,255,255, 0.2);
  }
  50%, 100% {
    background-color: rgba( 255,255,255, 1);
  }
}

.blackButton {
  border: 0px;
  margin-top: 2.2rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  color: var(--white);
  width: 100%;
  height: 7.3rem;
  background: var(--primary);
  border-radius: 20px;
}

.greenButton {
  background :var(--primary);
}