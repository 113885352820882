.bmi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  border-radius: 16px;
  padding: 24px;
  &.normal {
    background-color: #e7f5f1;
  }
  &.obese,
  &.underweight {
    background-color: #f5e7e7;
  }
  &.overweight {
    background-color: #f9f7ec;
  }

  h6 {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
}
