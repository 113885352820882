@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    
}