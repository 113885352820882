.container {
  h2 {
    text-align: center;
  }

  .group {
    margin-top: 24px;
    .groupTitle {
      text-transform: uppercase;
      color: #000;
      opacity: 0.5;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .cards {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
