.card {
  background-color: var(--card-bg);
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 16px;
  justify-content: space-between;
  &.checked {
    background-color: var(--primary-hover);
    .content {
      .icon {
        background-color: #fff;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    .icon {
      background-color: #0000000f;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .text {
      font-size: 14px;
      font-weight: 500;
      .paragraph {
        font-size: 13px;
        color: #000;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .card {
    .content {
      .text {
        font-size: 16px;
      }
    }
  }
}
