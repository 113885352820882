.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h2 {
    text-align: center;
  }

  .image {
    margin-top: 24px;
    width: 100%;
  }

  .hints {
    margin-top: 12px;
    padding: 16px;
    background-color: var(--card-bg);
    border-radius: 16px;
    font-size: 1.6rem;
  }
  .action {
    padding-top: 16px;
  }
}
