.benefits {
  margin-bottom: 30px;
  padding: 0 16px;

  .title {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    color: #333;

    .icon {
      flex-shrink: 0;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2.86px 17.14px 0px #1e205014;

      img {
        width: 28px;
        height: 28px;
      }
    }

    p {
      font-weight: 500;
      font-size: 15px;
      margin: 0;
    }
  }
}
