.personalPlan {
  padding: 0 16px;
  margin-bottom: 24px;
  h2 {
    text-align: center;
    margin-bottom: 4px;
    line-height: 38px;
  }

  span {
    color: var(--main-pink);
  }
  .card {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid rgba(5, 141, 102, 0.32);

    &.outlined {
      background-color: transparent;
    }
    &.filled {
      background-color: rgba(5, 141, 102, 0.08);
    }
    h3 {
      font-size: 17px !important;
      font-weight: 600;
    }
    h4 {
      font-size: 15px;
    }
    .countdown {
      color: #f91e1e;
    }
  }
  .cardListItemWithIcon {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      width: 24px;
    }
  }
}
