.input {
  display: flex;
  align-items: center;
  background-color: var(--card-bg);
  border-radius: 16px;
  font-size: 20px;
  line-height: 26px;
  padding: 20px;
  h5 {
    margin-bottom: 0 !important;
  }
  input {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    text-align: center;
    font-weight: 600;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: #000;
      opacity: 0.5;
      font-weight: 500;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
