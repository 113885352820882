.modalOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;

 

  &.modalEnter .content {
    opacity: 0;
    transform: translateY(200px);
  }

  &.modalEnterActive .content {
    opacity: 1;
    transform: translateY(0px);
    transition: 0.8s ease;
  }

  &.modalExit .content {
    opacity: 1;
  }

  &.modalExitActive .content {
    opacity: 0;
    transition: 0.4s ease;
  }

  &.modalExit {
    opacity: 1;
  }

  &.modalExitActive {
    opacity: 0;
    transition: 0.4s ease;
  }

  .content {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
  }
}
