.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
  }
  .img {
    height: 236;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h5 {
    font-size: 27px;
    margin-bottom: 8px;
    margin-top: 16px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;

    span {
      font-size: 13px;
      color: #a1a1a1;
    }
  }

  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}
