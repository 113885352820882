.inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardInput,
.inputField {
  padding: 12px;
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  background-color: #fff;
}

.splitFields {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.expiryDate,
.cvv {
  flex: 1;
}

.inputField {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
}

.inputField::placeholder {
  color: #aab7c4;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

.error {
  .cardInput,
  .inputField {
    border-color: #dc3545;
  }

  &.shakeOnSubmit {
    .cardInput,
    .inputField {
      animation: shake 0.2s ease-in-out;
    }
  }
}