.workouts {
  padding: 0 2rem;
  padding-bottom: 2rem;
  max-width: 500px;
  margin: 0 auto;
  .subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    color: #9fa3b6;
    margin-top: 0.8rem;
  }
  .cards {
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;
    gap: 2rem;
    .card {
      cursor: pointer;
      padding: 2.4rem;
      border-radius: 1.6rem;
      background-size: cover;
      background-position: center center;
      h5 {
        font-size: 2.2rem;
        font-weight: 700;
        color: #fff;
      }
      p {
        margin-top: 5rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}
