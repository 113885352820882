.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .cards {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  .img {
    height: 236;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h5 {
    font-size: 27px;
    margin-bottom: 8px;
    margin-top: 16px;
    font-weight: 600;
    text-align: center;
  }
}
