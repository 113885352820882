.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;

    span {
      line-height: 38px;
      color: #7c8af7;
    }
    input {
      width: 100%;
      height: 6.7rem;
      padding: 24px 22px;
      margin-top: 3rem;
      background-color: #f8f8f8;
      border: 0;
      border-radius: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      outline: none;
      &::placeholder {
        color: #000;
        opacity: 0.4;
      }
    }
    .error {
      color: red;
    }

    .hint {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;

      p {
        font-size: 10px;
        font-weight: 500;
        color: #0000007a;
      }
    }
  }
}
