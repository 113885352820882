.transform {
  .transformContent {
    max-width: 1280px;
    padding: 0 30px;
    padding-top: 72px;
    margin: 0 auto;
  }

  .title {
    max-width: 768px;
    text-align: center;
    margin: 0 auto;

    h2 {
      font-size: 36px !important;
    }

    p {
      margin-top: 20px;
      font-size: 20px;
      line-height: 30px;
      color: #667085;
      font-weight: 400;
    }
  }

  .nowAfter {
    background-color: #f8f8f8;
    background-image: url("./img/bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 32px;
    padding: 16px;
    padding-bottom: 0;
    margin: 0 auto;
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 20px;
  }
  .headerItem {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    width: 50%;
    padding: 30px 0;
    cursor: pointer;

    span {
      font-weight: 600;
      color: #7c8af7;
    }
  }

  .headerDivider {
    height: 47px;
    width: 1px;
    background-color: #d9d9d9;
  }

  .body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      z-index: 1;

      .beforeImage,
      .afterImage {
        height: 485px;
        width: auto;
      }

      .afterImage {
        margin-left: -64px;
      }

      img {
        height: 100%;
        display: block;
      }

      .comparisonImage {
        z-index: 1;
      }
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 46.5px;
    height: 100%;
    .cardsWrapper {
      display: flex;
      gap: 16px;
      flex: 1;
    }
    .metric {
      background-color: #f7f7f7;
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 32px 24px;
      justify-content: space-between;
    }

    .metricContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .metricTitle {
      color: #667085;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }

    .value {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: #000;
    }

    .levelIndicators {
      margin-top: 12px;
      display: flex;
      justify-content: left;
      gap: 5px;
      flex: 1;
    }

    .indicator {
      width: 100%;
      height: 8px;
      background-color: #7c8af7;
      opacity: 0.2;
      border-radius: 13px;
    }

    .indicator.filled {
      opacity: 1;
    }
  }

  .divider {
    width: 1px;
    height: auto;
    background-color: #d9d9d9;
  }
}

@media screen and (max-width: 1024px) {
  .transform {
    .body {
      .imageWrapper {
        .beforeImage,
        .afterImage {
          position: relative;
          height: 414px;
        }
      }
    }
    .cards {
      gap: 21px;
      .cardsWrapper {
        flex-direction: column;
      }
      .metric {
        padding: 24px 16px;
      }

      .metricTitle {
        font-size: 16px;
        line-height: 24px;
      }

      .value {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 744px) {
  .transform {
    .headerItem {
      font-size: 22px;
    }
    .body {
      .imageWrapper {
        .beforeImage,
        .afterImage {
          height: 233px;
        }
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .transform {
    .title {
      h2 {
        font-size: 27px !important;
      }
      p {
        font-size: 18px;
      }
    }
    .transformContent {
      padding: 0 16px;
      padding-top: 42px;
    }
    .headerItem {
      font-size: 13px;
      padding: 12px 0;
    }

    .headerDivider {
      height: 29px;
      width: 1px;
      background-color: #d9d9d9;
    }

    .body {
      .imageWrapper {
        .beforeImage,
        .afterImage {
          height: 160px;
        }
      }
    }
    .nowAfter {
      border-radius: 16px;
      padding: 8px;
      padding-bottom: 0;
    }
    .header {
      border-radius: 8px;
    }
    .cards {
      gap: 16px;

      .cardsWrapper {
        flex-direction: column;
      }
      .metric {
        padding: 10px 12px;
      }

      .metricTitle {
        font-size: 12px;
        line-height: 22px;
      }

      .value {
        font-size: 15px;
        line-height: 22px;
      }
      .indicator {
        height: 7px;
      }
    }
  }
}
