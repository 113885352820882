.container {
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  margin: 0;
  padding: 0 16px;
  position: relative;
  h2 {
    text-align: center;
  }

  .image {
    z-index: 2;
    position: absolute;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .cards {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 192px;
    margin-top: 24px;
    .card {
      background-color: var(--card-bg);
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      padding: 16px;
      line-height: 18px;
      letter-spacing: -0.01em;
      text-align: left;
      &:active {
        background-color: var(--primary-hover);
      }
    }
  }
  .hint {
    box-shadow: 0px 4px 24px 0px #1e205014;
    padding: 20px 16px;
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (min-width: 430px) {
  .container {
    .cards {
      .card {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
