.container {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 8px;

  .sliderWrapper {
    padding: 10px;
    background-color: #f6f7f9;
    border-radius: 16px;
    min-height: 510px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .slide {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  .slickPrev,
  .slickNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    &:before {
      font-size: 20px;
      color: #333;
    }
  }

  .slickPrev {
    left: 10px;
  }

  .slickNext {
    right: 10px;
  }

  .description {
    margin-top: 20px;
    text-align: left;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }

    .story {
      color: #000;
      opacity: 0.4;
      font-weight: 400;
      line-height: 18px;
    }

    .weightChange {
      color: #f91e1e;
      font-weight: 600;
    }

    .readMore {
      color: #7c8af7;
      font-weight: 500;
      font-size: 12px;
      display: inline-block;
    }
  }

  .disclaimer {
    margin-top: 20px;
    font-size: 14px;
    color: #000;
    opacity: 0.4;
    text-align: left;
  }
}
