.fade-in-enter {
  opacity: 0;
  transform: translateY(400px);
  transition: 0.7s ease;
  transition: opacity 0.7s ease, transform 0.7s ease;
}
.fade-in-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: 0.7s ease;
  transition: opacity 0.7s ease, transform 0.7s ease;
}
.fade-in-exit {
  opacity: 1;
  transform: translateY(100px);
  transition: opacity 0.7s ease, transform 0.7s ease;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 0.7s ease, transform 0.7s ease;
}
