.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  input {
    width: 100%;
    height: 6.7rem;
    padding: 24px 22px;
    margin-top: 3rem;
    background-color: #f8f8f8;
    border: 0;
    border-radius: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    outline: none;
    &::placeholder {
      color: #000;
      opacity: 0.4;
    }
  }
}
