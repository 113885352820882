.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    span {
      color: var(--main-pink);
    }
  }
  .loaderContainer {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e4e6e7;
    overflow: hidden;
    margin-top: 24px;

    .loaderFill {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: var(--main-pink);
      z-index: 1;
    }
    p {
      text-shadow: 2px 2px #0000001b;
      font-size: 20px;
      font-weight: 600;
      z-index: 2;
      color: #fff;
    }
  }
  .chart {
    margin-top: 16px;
    position: relative;
    .chartLabel {
      position: absolute;
      right: 48px;
      top: 107px;
      display: flex;
      align-items: center;
      gap: 6px;
      color: #fff;
      font-size: 14px;
      svg {
        transform: rotate(-90deg);
        height: 8px;
        path {
          fill: #fff;
        }
      }
    }
  }
}

.radiance {
  position: absolute;
  top: 146px;
  left: 271px;
  animation: radiate 2s infinite ease-out;
  transform: scale(0.5);
  transform-origin: center;
  background-color: #7c8af7;
  opacity: 0.4;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  //   transform: translate(0);
}

@keyframes radiate {
  0% {
    transform: translate(0, 0) scale(0.5);
    opacity: 0.1;
  }
  50% {
    transform: translate(0, 0) scale(1);
    opacity: 0.4;
  }
  100% {
    transform: translate(0, 0) scale(0.5);
    opacity: 0.1;
  }
}
