.productCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
