.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  h2 {
    text-align: center;
  }
  .cards {
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-direction: column;

    .card {
      padding-left: 16px;
      width: 100%;
      height: 80px;
      border-radius: 16px;
      background-color: #f1f2f3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.checked {
        background-color: var(--primary-hover);
      }
      .text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        gap: 12px;
      }
      .img {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}

.modal {
  padding: 24px 16px;
  h5 {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    margin-top: 16px;
    font-size: 16px;
    margin-bottom: 24px;
  }
}
