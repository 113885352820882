.brands {
  text-align: center;
  background-color: #f6f7f9;

  h2 {
    margin-bottom: 0;
    font-size: 30px !important;
  }

  .brandsContent {
    padding: 56px 32px;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
    row-gap: 24px;
    img {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    .brandsContent {
      padding: 40px 32px;
    }
  }

  @media screen and (max-width: 375px) {
  }
}