.hero {
  background: url(../../../assets/img/png/bg.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 91px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .title {
    max-width: 1280px;
    padding: 0 60px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 60px;
    h1 {
      font-size: 56px !important;
      font-weight: 600 !important;
      color: #fff !important;
      line-height: 60px !important;
      letter-spacing: -3.16px !important;
      max-width: 505px;
    }
    .cta {
      margin-top: 42px;
      border: 0;
      outline: 0;
      border-radius: 99999px;
      background-color: #fff;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-size: 15px !important;
      line-height: 24px;
      letter-spacing: -0.64px;
      font-weight: 600;
      padding: 16px 40px;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    .title {
      h1 {
        max-width: none;
      }
    }
  }
}

@media (max-width: 744px) {
  .hero {
    background-position: 60%;
    .title {
      padding: 0 32px;
      h1 {
        font-size: 48px;
        max-width: 404px;
      }
    }
  }
}

@media (max-width: 445px) {
  .hero {
    background-position: 60%;

    .title {
      font-size: 48px;
      padding: 0 24px;
    }
  }
}
