.container {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 8px;

  .sliderWrapper {
    padding: 10px;
    background-color: #f6f7f9;
    border-radius: 16px;
    min-height: 430px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .slide {
    position: relative;

    img {
      border-radius: 8px;
      width: 100%;
    }
    .titleBlock {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff;
      padding: 10px 20px;
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      font-size: 16px;
      width: 95%;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
    }
  }

  .slickPrev,
  .slickNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    &:before {
      font-size: 20px;
      color: #333;
    }
  }

  .slickPrev {
    left: 10px;
  }

  .slickNext {
    right: 10px;
  }

  .description {
    position: relative;
    margin-top: 20px;
    text-align: left;

    p {
      color: #000;
      opacity: 0.4;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .disclaimer {
    margin-top: 60px;
    font-size: 14px;
    color: #000;
    opacity: 0.4;
    text-align: left;
  }
  .dots {
    bottom: -50px;
    li {
      margin: 0;
      button:before {
        font-size: 9px;
      }
    }
  }
}
