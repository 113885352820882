.benefits {
  margin-bottom: 30px;
  padding: 0 16px;

  .title {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    color: #333;

    .icon {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background-color: #0000000f;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-weight: 500;
      font-size: 15px;
      margin: 0;
    }
  }
}
