.countdownTimer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #ff4f64;
  color: white;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
}
