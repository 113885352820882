.emailVersion { 
  padding: 16px;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
h3 {
  font-size: 28px;
  font-weight: 700;
}
h5 {
  font-weight: 500;
  margin-top: 12px;
  color: #9396a6;
  font-size: 16px;
  span {
    color: var(--main-pink);
  }
}
p {
  margin-top: 24px;
  font-weight: 500;
  font-size: 13px;
  a {
    color: var(--main-pink) !important;
  }
}
}

.appleVersion {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  position: relative;
  padding-bottom: 80px;

  h2 {
    font-size: 28px;
    font-weight: 700 !important;
    margin-bottom: 8px;
  }

  .subtitle {
    color: #9396A6;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 32px;
  }

  .step {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    position: relative;
    background-color: #F8F8F8;
    border-radius: 24px;
    padding: 24px;

    .stepNumber {
      width: 42px;
      height: 42px;
      background-color: var(--primary);
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 16px;
      letter-spacing: -0.5px;
      color: #9396A6;

      span {
        color: #000;
      }
    }

    .link {
      color: var(--primary);
    }

    .appStoreButton {
      width: 180px;
      margin-top: 8px;
      
      img {
        width: 100%;
        height: auto;
      }
    }

    .screenImage {
      width: 100%;
      height: auto;
      border-radius: 16px;
    }
  }

  .action {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    max-width: 500px;
  }
}
