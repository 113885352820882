.getPlanButton {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 9px;
  background-color: #000000;
  &:disabled {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}
.spinner {
  width: 26px;
  height: 26px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.skipButton {
  width: 100%;
  padding: 15px;
  background-color: transparent;
  color: #9396a6;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}