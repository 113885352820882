@import "src/common/animation/animation.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @extend .fade-in;

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  .inputWrapper {
    margin-top: 24px;
    margin-bottom: 16px;

    .inputContainer {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    
    .hint {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #0000007a;
      span {
        color: #000;
      }
    }
    h5 {
      font-size: 20px;
    }
  }

  .action {
    padding-top: 16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  border-radius: 16px;
  padding: 24px;
  background-color: #f0f2f5;
  &.low {
    background-color: #f5e7e7;
  }
  h6 {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
}
