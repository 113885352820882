.faqItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 24px 0 32px 0;

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-weight: 500;
      width: 80%;
      font-size: 18px;
    }
  }

  .arrow {
    transition: transform 0.3s ease;
  }

  .open {
    transform: rotate(180deg);
  }

  .answer {
    max-width: 90%;
    margin-top: 8px;
    color: #667085;
    font-size: 16px;
    line-height: 28px;
  }
}
