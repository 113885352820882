.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  h2 {
    text-align: center;
  }
  .cards {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
