.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 16px;
  border-bottom: 1px solid #ececec;

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -1px;
    text-align: center;
  }

  .skipButton {
    margin-left: auto;
    margin-top: 2px;
    padding: 15px;
    background-color: transparent;
    color: #9396a6;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
    font-weight: 600;

    svg {
      margin-left: 7px;
    }
  }
}
