.faqItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0;

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-weight: 500;
      width: 80%;
      font-size: 19px;
    }
  }

  .arrow {
    transition: transform 0.3s ease;
  }

  .open {
    transform: rotate(180deg);
  }

  .answer {
    margin-top: 10px;
    color: #000;
    opacity: 0.4;
    font-size: 13px;
  }
}
