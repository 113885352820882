.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .cardsWithImage {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 52px;

    .targetZonesContainer {
      position: absolute;
      top: -36px;
      left: -16px;
      height: 100%;
      width: 100%;
      z-index: 2;
      .targetZoneImage {
        mix-blend-mode: hard-light;
        transition: opacity 0.4s ease;
      }
      img {
        object-fit:cover;
        object-position: left top;
        width: 100%;
        height: auto;
        position: absolute;
      }
    }
  }

  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  h2 {
    position: relative;
    z-index: 3;
    text-align: center;
  }
  .cards {
    z-index: 3;
    width: 136px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 12px;
    flex-direction: column;
    & > div {
      padding: 16px;
    }
    button {
      width: 100%;
    }
  }
  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    z-index: 3;
    position: sticky;
    background-color: #fff;
  }
}

.modal {
  padding: 24px 16px;
  h5 {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    margin-top: 16px;
    font-size: 16px;
    margin-bottom: 24px;
  }
}
