.button {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 9999px;
  background-color: var(--primary);
  padding: 16px;
  width: 100%;
  border: 0;
  outline: 0;
  transition: .4s ease;
  letter-spacing: 1.41px;
  text-transform: uppercase;
  &:disabled {
    background-color: #7c8af75d
  }
  &:active {
    transform: scale(1.05);
  }

}
