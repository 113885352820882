.container {
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;

  p {
    font-size: 20px;
    font-weight: 500;
  }
  .action {
    padding-top: 16px;
  }
}
