.paypal {
  background-color: #ffc439;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  gap: 12px;
  border-radius: 9999px;

  img {
    width: 87px;
  }
}

.paypal:hover {
  background-color: #ffc439;
}
