.container {
  background-image: url("./img/bg.jpg");
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: -20px;

  h2 {
    text-align: center;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 192px;
    margin-top: 16px;
    .card {
      background-color: var(--card-bg);
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      padding: 16px;
      line-height: 18px;
      letter-spacing: -0.01em;
      text-align: left;
      &:active {
        background-color: var(--primary-hover);
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .container {
    .cards {
      .card {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
