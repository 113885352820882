.spinner {
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 5px solid rgba(124, 138, 247, 1);
  border-right: 5px solid rgba(124, 138, 247, 1);
  border-bottom: 5px solid rgba(124, 138, 247, 1);
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
