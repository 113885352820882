.featuresList {
  margin: 16px 0 32px 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 14px;
    color: #9396a6;
    font-weight: 500;
  }
}
