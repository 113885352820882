.paymentOptionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;

  .logos {
    display: flex;
    justify-content: center;
  }
}

.otherPaymentButton {
  background-color: transparent;
  color: #9396a6;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 8px;
  font-size: 16px;
}

.androidPlanButton {
  margin-top: 16px;
}
