.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hidden {
    opacity: 0;
  }
  h2 {
    text-align: center;
    line-height: 38px;
    span {
      color: #7c8af7;
    }
  }

  .chart {
    margin-top: 16px;
    position: relative;
    transition: 0.4s ease;
    .title {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      font-size: 1.4rem;
      font-weight: 500;
    }
    .now {
      text-align: center;
      font-weight: 500;
      animation-delay: 1s;
      position: absolute;
      padding-top: 0.4rem;
      top: 50px;
      left: 57px;
      font-size: 14px;
      line-height: 17px;
      &.gain {
        top: 190px;
        left: 57px;
      }
    }
    .after {
      text-align: center;
      font-weight: 500;
      opacity: 0;
      animation: show 0.4s ease forwards;
      animation-delay: 1s;
      position: absolute;
      padding-top: 0.4rem;
      top: 133px;
      left: 268px;
      color: #fff;
      font-size: 14px;
      line-height: 17px;
      &.gain {
        top: 93px;
        left: 267px;
      }
    }
    .axisx {
      position: absolute;
      bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      right: 0;
      width: 100%;
      padding-left: 32px;
      padding-right: 8px;
    }

    .axisx {
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .hint {
    margin-top: 12px;
    color: #000000;
    opacity: 0.4;
    font-size: 14px;
    font-weight: 500;
    align-self: start;
  }
}

.show {
  opacity: 0;
  animation: show 0.4s ease forwards;
  animation-delay: 1s;
}

.showOpacity {
  opacity: 0;
  animation: showOpacity 0.4s ease forwards;
  animation-delay: 1s;
}

.grow {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: grow 2s ease forwards;
}

@keyframes grow {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes showOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.12;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
