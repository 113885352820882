.container {
  text-align: center;
  padding-bottom: 16px;
  h1 {
    font-size: 32px !important;
    text-transform: uppercase;
  }
  .quiz {
    font-weight: 600;
    margin-top: 16px;
    font-size: 12px;
  }
  .release {
    color: rgba($color: #000000, $alpha: 0.5);
    margin-top: 16px;
    font-weight: 500;
    font-size: 20px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
  }
  .card {
    background-color: var(--card-bg);
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    img {
      margin-top: 8px;
      width: 100%;
    }
    &:active {
      background-color: var(--primary-hover);
    }
    .label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--primary);
      color: #fff;
      padding: 10px 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
    }
  }

  .privacy {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    a {
      color: var(--primary);
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    h1 {
      font-size: 28px !important;
    }
  }

}
