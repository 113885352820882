.container {
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  position: relative;

  .title {
    z-index: 3;
    position: relative;
  }

  p {
    font-size: 20px;
    font-weight: 500;
  }
  .action {
    padding-top: 16px;
    z-index: 3;
    position: relative;
  }

  .image {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
}
