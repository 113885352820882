.layout {
  max-width: 480px;
  margin: 0 auto;
}

.container {
  max-width: 480px;
  margin: 0 auto;
  padding: 0 16px;
}

.footer {
  text-align: center;
  opacity: 0.4;
  font-size: 14px;
  margin-bottom: 20px;
}
