.page {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding-top: 3.4rem;
    width: 100%;
    height: 100vh;
    max-width: 375px;
    .backToProfileButton {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .backToProfileText {
            margin-bottom: 3.6rem;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-right: 2.4rem;
            color: #000000;
        }
    }
    .carouselWrapper {
        width: 100%;
    }
    .nextTitle {
        margin-left: 1.4rem;
        margin-top: 3.5rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #000000;
    }
    .nextVideoWrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 2.3rem 1rem 1rem;
        height: 57px;
        background: #F5F4F1;
        border-radius: 10px;
        margin-top: 0.7rem;
        margin-left: 1.4rem;
        margin-right: 1.4rem;
        .nextVideoContentWrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
            .nextVideoImage {
                width: 37px; 
                height: 37px;
                border-radius: 5px;
            }
            .nextVideoInfo {
                display: flex;
                flex-direction: column;
                margin-left: 1.2rem;
                .nextVideoTitle {
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    color: #000000;
                }
                .nextVideoSubtitle {
                    font-weight: 400;
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    color: #797979;
                }
            }
        }
    }
}

.carouselIndicatorsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    .carouselIndicator {
        cursor: pointer;
        width: 32px;
        height: 3px;
        margin-right: 5px;
        background-color: #C9C9C9;
    }
    .carouselIndicatorActive {
        background-color: var(--primary);
    }
}