.card {
  background-color: var(--card-bg);
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 16px;
  .icon {
    background-color: #0000000f;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    .paragraph {
      font-size: 13px;
      color: #000;
      opacity: 0.5;
    }
  }
  &:active {
    background-color: var(--primary-hover);
    .icon {
      background-color: #fff;
    }
  }
}

@media screen and (min-width: 430px) {
  .card {
    .text {
      font-size: 16px;
    }
  }
}
