.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
  }
  .icon {
    background-color: white;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 4px 24px 0px #1e205014;

    img {
      width: 40px;
      height: 40px;
    }
  }
  h5 {
    font-size: 27px;
    margin-bottom: 8px;
    margin-top: 16px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }

  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}
