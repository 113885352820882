.container {
  margin-bottom: 30px;
  padding: 0 16px;

  .title {
    text-align: center;
  }
  .review {
    margin-top: 16px;
    background-color: var(--card-bg);
    border-radius: 16px;
    padding: 15px 20px;
    .stars {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: #f7c056;
        font-size: 20px;
      }
    }
    .name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .paragraph {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.4;
    }
  }
}
