.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contentWrapper {
  h2 {
    text-align: center;
  }
  .content {
    margin: 24px 0;
    border: 1px solid #0000001a;
    background-color: #FDFDFD;
    overflow: hidden;
    border-radius: 16px;
    padding: 24px 16px 0 16px;
    h3 {
      margin-bottom: 48px;
      font-size: 18px !important;
    }
    .hint {
      margin-top: 16px;
      transition: 0.4s ease;
    }
    .summary {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 24px;

      .summaryItems {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 204px;
        .summaryItem {
          display: flex;
          align-items: center;
          gap: 8px;

          .icon {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            background-color: #0000000f;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .text {
            display: flex;
            flex-direction: column;
            width: 90px;
            p {
              font-size: 12px;
              color: #000;
              opacity: 0.5;
            }
            h5 {
              text-transform: capitalize;
              font-size: 14px !important;
              margin: 0 !important;
            }
          }
        }
      }
      .image {
        width: 100%;
        height: 268px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
        }
      }
    }
  }
}

.action {
  padding-top: 16px;
  margin-bottom: -24px;
  padding-bottom: 24px;
  bottom: 0;
  position: sticky;
  background-color: #fff;
}

.hidden {
  opacity: 0;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
