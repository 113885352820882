.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ffffff1a;

  .content {
    max-width: 1280px;
    padding: 0 60px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 14px 60px;
    height: 90px;
    .burger {
      display: none;
    }
    img {
      z-index: 3;
    }

    .navigation {
      color: #fff;
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        gap: 24px;
        padding: 0;
        margin: 0;
        padding: 0 16px;
        li {
          text-align: center;
          a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
          }
          width: 100%;
        }
      }
      .loginNav {
        display: none;
      }
      .contact {
        display: none;
      }
    }

    button {
      background-color: #fff;
      color: black;
      display: flex;
      flex: 0;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-size: 15px !important;
      line-height: 24px;
      letter-spacing: -0.64px;
      font-weight: 600;
      padding: 16px 40px;
      gap: 10px;
    }
  }
}

.burger {
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

.burger span:nth-child(1) {
  top: 0px;
}

.burger span:nth-child(2),
.burger span:nth-child(3) {
  top: 6px;
}

.burger span:nth-child(4) {
  top: 12px;
}

.burger.open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

.burger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@media (max-width: 743px) {
  .header {
    .content {
      padding: 0 32px;
    }
    .navigation {
      display: none;
      transition: opacity 0.4s ease;

      &.open {
        display: flex;
        opacity: 1;
      }

      &.close {
        opacity: 0;
        display: none !important;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid #ffffff1a;
        height: 90px;
      }

      position: absolute;
      transition: opacity 0.4s ease;
      background-color: #000000e5;
      backdrop-filter: blur(8px);
      height: 100vh;
      top: 0;
      left: 0;
      width: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      ul {
        margin-top: 154px !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        li {
          a {
            font-weight: 400 !important;
            font-size: 32px !important;
          }
        }
      }
      .loginNav {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
      }
      .contact {
        margin-top: auto !important;
        display: block !important;
        text-align: center;
        font-size: 16px;
        padding: 16px;
        font-weight: 500;
        padding-bottom: 40px;
        a {
          color: var(--main-pink);
        }
      }
    }
    .content {
      .burger {
        z-index: 3;
        display: block;
      }
      .login {
        display: none;
      }
    }
  }
}

@media (max-width: 445px) {
  .header {
    .content {
      padding: 0 24px;
    }
  }
}
