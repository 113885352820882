.imageCard {
  width: 100%;
  border-radius: 16px;
  background-color: var(--card-bg);
  border-radius: 16px;
  padding: 0 4px 4px 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: .4s ease;
  position: relative;
  img {
    width: 100%;
  }

  .label {
    position: absolute;
    bottom: 12px;
    width: 100%;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding: 8px;
    line-height: 22px;
    box-shadow: 0px 1px 4px 0px #00000014;
    border-radius: 12px;
    text-align: center;

  }

  &:active {
    background-color: var(--primary-hover);
  }
  &.checked {
    background-color: var(--primary-hover);
  }
}
