.features {
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  row-gap: 72px;

  .featureBlock {
    .content {
      padding: 0 32px;
      margin: 0 auto;
      max-width: 1280px;
      display: flex;
      align-items: center;
      gap: 96px;
      position: relative;

      .text {
        flex: 1;
      }

      &.reversed {
        flex-direction: row-reverse;
        .imageContent {
          flex: 1;
          width: 100%;
          justify-content: flex-start;
        }
      }

      .textContent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 580px;
        text-align: start;

        h2 {
          font-size:  30px !important;
          font-weight: 600;
          margin-bottom: 24px;
          text-align: start;
          line-height: 38px;
        }

        p {
          color: #667085;
          font-size: 18px;
          line-height: 1.6;
        }
      }

      .imageContent {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

.featuresItemList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  li {
    padding: 0;
    display: flex;
    gap: 16px;
    align-items: center;
    .emoji {
      flex-shrink: 0;
      height: 40px;
      width: 40px;
      padding: 6px;
      border-radius: 50%;
      box-shadow: 0px 2.86px 17.14px 0px #1e205014;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .features {
    row-gap: 48px;
    .featureBlock {
      .content {
        gap: 43px;
      }
    }
  }
}

@media (max-width: 744px) {
  .features {
    .featureBlock {
      .content {
        flex-direction: column !important;
        gap: 32px;
        text-align: center;

        .textContent {
          max-width: 100%;

          h2 {
            font-size: 32px;
            margin-bottom: 16px;
          }

          p {
            font-size: 18px;
          }
        }

        .imageContent {
          justify-content: center;
          max-height: 428px;
          width: 100%;
          overflow: hidden;
          img {
            object-position: bottom;
            height: auto;
            width: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .features {
    .featureBlock {
      .content {
        padding: 0 16px;
        .textContent {
          h2 {
            font-size: 24px !important;
          }
        }
      }
    }
  }
}
