.modalOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  overflow-y: auto;
  background: #fff;
  width: 100%;
  max-width: 400px;
  height: 90%;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  position: relative;
  text-align: left;
  animation: slideUp 0.3s ease-out;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);

  h3 {
    font-size: 20px !important;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 32px;
  line-height: 24px;
  background: none;
  padding: 0;
  border: none;
  color: #9396a6;
  cursor: pointer;
}

.switch {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px;
  background-color: #f6f7f9;
  border-radius: 16px;
  gap: 6px;
  .switchItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 12px 0px;
    &.active {
      background-color: #fff;
      box-shadow: 0px 4px 24px 0px #1E205014;

      h5 {
        color: #000;
      }

      img {
        opacity: 1;
        filter: grayscale(0);
      }

      .cards {
        img {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }
  }
  .paypal {
    img {
      height: 25px;
      opacity: 0.7;
      filter: grayscale(0.95);
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      font-size: 17px;
      color: #9396a6;
    }
    .cards {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      img {
        opacity: 0.7;
        filter: grayscale(1);
        flex: 1;
        height: 16px;
      }
    }
  }
}

.price {
  margin-top: 16px;
  hr {
    margin: 0;
    color: #9396a6;
  }
  .regularPrice,
  .totalPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #000;
    }
  }
  .regularPrice {
    padding-bottom: 10px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .totalPrice {
    padding-top: 16px;
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.payment {
  margin-top: 24px;
  .paypal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    p {
      font-size: 14px;
      text-align: center;
      font-weight: 500;
    }
  }
}

.planDetails {
  display: flex;
  justify-content: space-between;

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #9396a6;
    font-weight: 500;

    span {
      color: #f91e1e;
    }
  }
}

.paymentSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9f7eb;
  padding: 15px;
  border-radius: 8px;
  font-weight: bold;

  span {
    color: #25b67d;
  }

  margin: 10px 0;
  text-align: center;
}

.totalAmount {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  p {
    font-size: 18px;
    span {
      color: #9396a6;
      font-weight: 500;
    }
  }
}

.discountText {
  text-align: right;
  color: #f91e1e;
  font-weight: 600;
}

.cardWrapper {
  margin-top: 18px;
}

.paymentButton {
  min-height: 10px;
}
