.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h2 {
    text-align: center;
  }
  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    .card {
      overflow: hidden;
      border-radius: 16px;
      background-color: var(--card-bg);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      height: 68px;
      transition: 0.4s ease;
      p {
        font-weight: 500;
      }
      img {
        height: 100%;
      }
      &.checked {
        background-color: var(--primary-hover);
      }
    }
  }
  .action {
    padding-top: 16px;
  }
}

@media screen and (min-width: 430px) {
  .container {
    .cards {
      .card {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
