.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &.hidden {
    opacity: 0;
  }

  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hidden {
    opacity: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
    }

    .subtitle {
      text-align: center;
      font-size: 16px;
      color: #a1a1a1;
    }

    .predictedData {
      margin-top: 8px;
      text-align: center;
      color: var(--primary);
      font-size: 20px;
      font-weight: 600;
      span {
        color: #a1a1a1;
      }
    }
  }

  .event {
    margin-top: 8px;
    background-color: var(--card-bg);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;

    span {
      font-weight: 500;
    }
    .icon {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: #0000000f;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 80%;
      }
    }
  }
  .chart {
    margin-top: 16px;
    position: relative;
    transition: 0.4s ease;
    .eventLabel {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      background-color: #fff;
      padding: 0.4rem 1rem;
      border-radius: 0.8rem;
      font-size: 1.4rem;
    }
    .label {
      text-align: center;
      font-weight: 500;
      opacity: 0;
      animation: show 0.4s ease forwards;
      animation-delay: 1s;
      position: absolute;
      padding-top: 0.4rem;
      top: 131px;
      left: 283px;
      width: 55px;
      height: 44px;
      color: #fff;
      font-size: 14px;
      line-height: 17px;
      &.gain {
        top: 106px;
        left: 277px;
      }
      &.maintain {
        top: 51px;
        left: 268px;
      }
    }
    .axisx {
      position: absolute;
      bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      right: 0;
      width: 100%;
      padding-left: 32px;
      padding-right: 8px;
    }
    .axisy {
      position: absolute;
      left: 12px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 32px;
      padding-top: 16px;
      height: 100%;
    }
    .eventDate {
      position: absolute;
      bottom: 4px;
      padding-left: 32px;
      padding-right: 8px;
    }

    .axisx,
    .axisy,
    .eventDate {
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .hint {
    margin-top: 12px;
    margin-bottom: 24px;
    color: #a1a1a1;
    font-size: 11px;
    font-weight: 500;
  }
}

.show {
  opacity: 0;
  animation: show 0.4s ease forwards;
  animation-delay: 1s;
}

.showOpacity {
  opacity: 0;
  animation: showOpacity 0.4s ease forwards;
  animation-delay: 1s;
}

.grow {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: grow 2s ease forwards;
}

@keyframes grow {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes showOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
