.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  h2 {
    text-align: center;
  }
  .cards {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .action {
    padding-top: 16px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    bottom: 0;
    position: sticky;
    background-color: #fff;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 16px;
  .icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 24px 0px #1e205014;
    img {
      height: 38px;
      width: 38px;
    }
  }
  h4 {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;

    margin: 16px 0;
    text-align: center;
  }
}
