.bmiScale {
  text-align: center;
  font-family: Arial, sans-serif;

  .label {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .scale {
    position: relative;
    height: 8px;
    z-index: 3;

    background: linear-gradient(
      90deg,
      #36e6e6 0%,
      #64ec82 27.5%,
      #f9ed2a 53.5%,
      #ffa51f 74%,
      #f91e1e 100%
    );

    border-radius: 10px;
  }

  .pointer {
    position: absolute;
    bottom: -8px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform-origin: center center;
    z-index: 3;
    transform: translateX(-50%);
    left: 0;
    transition: 0.4s ease;

    .tooltip {
      background-color: #000;
      padding: 5px 10px;
      border-radius: 8px;
      font-weight: 500;
      color: #fff;
      font-size: 14px;
      position: relative;
      white-space: nowrap;
      &::before {
        content: "";
        background-color: #000;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -5px;
      }
    }
    .line {
      background-color: #000;
      width: 2px;
      height: 30px;
      &.left {
        align-self: flex-start;
        margin-left: 1px;
      }
    }
    .slider {
      height: 24px;
      width: 24px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 16px;
        width: 16px;
        background-color: #fff;
        border-radius: 50%;
      }
    }

    &.center {
      .tooltip {
        &::before {
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }
    &.left {
      align-items: flex-start;
      transform: translateX(-10%);
      .tooltip {
        &::before {
          left: 9px;
          transform: translateX(0) rotate(45deg);
        }
      }
      .line {
        margin-left: 13px;
      }
    }
    &.right {
      align-items: flex-end;
      transform-origin: right;
      transform: translateX(-90%);
      .tooltip {
        &::before {
          right: 9px;
          transform: translateX(0) rotate(45deg);
        }
      }
      .line {
        margin-right: 13px;
      }
    }
  }

  .rangeLabels {
    margin-bottom: 12px;
    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        width: 1px;
        height: 28px;
        background-color: #7f7f7f;
        opacity: 0.4;
      }
    }
  }

  .rangeLabels,
  .categories {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;

    span {
      text-align: center;
      text-transform: uppercase;
      color: #000;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
}
