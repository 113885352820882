
.contentWrapper {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    .contentItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;
      padding-left: 2rem;
      width: 100%;
      img {
        width: 7.9rem;
        height: 7.9rem;
        border-radius: 2rem;
        margin-right: 1.7rem;
      }
      .infoWrapper {
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: flex-start;
      }
      .contentTitle {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        color: #000000;
      }
      .contentSubtitle {
        margin-top: 1.1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        color: #000000;
      }
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }