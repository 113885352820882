.keyboardButton {
  position: fixed;
  right: 0;
  padding: 16px;
  margin: 0 auto;
  z-index: 11;
  width: 100%;
  animation: slideFromRight 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.036) 16.67%, rgba(255, 255, 255, 0.15) 34.38%, rgba(255, 255, 255, 0.79) 66.15%, rgba(255, 255, 255, 0.95) 83.85%, #FFFFFF 100%);


  &.exit {
    transform: translateX(100%);
    opacity: 0;
  }

}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
