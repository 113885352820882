.timerContainer {
  display: flex;
  background-color: #fff;
  align-items: center;
  gap: 16px;
  flex: 1;
  padding: 10px 26px;
  border-bottom: 1px solid #ececec;
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: slideDown 0.6s ease;

  .button {
    flex: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timer {
  display: flex;
  text-align: center;
  justify-content: space-between;

  p {
    font-size: 32px;
    color: #7070e3;
    font-weight: 700;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .time {
    flex-direction: column;
  }
}
