.profileSettingsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 2.5rem 4.4rem 2.5rem;
    .profileSettingsTitle {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: #000000;
    }
    .logOutButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 5.5rem;
      padding: 2.4rem 2.2rem;
      margin-top: 3rem;
      border: 1px solid rgba(125, 125, 125, 0.2);
      border-radius: 20px;
      color: var(--white);
      outline: none;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.2rem;
      background: var(--black);
    }
  
    .cancelSubscription {
      margin-top: 3.3rem;
      border: 0px;
      background: var(--white);
      outline: 0px;
      color: var(--primary);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }