.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    opacity: 0.5;
  }

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .inputWrapper {
    margin-top: 24px;
    margin-bottom: 16px;

    .input {
      position: relative;
    }
    ::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      z-index: 2;
    }

    .hint {
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: center;
      line-height: 18px;
      margin-top: 8px;
      font-size: 10px;
      font-weight: 500;
      color: #0000007a;
      a {
        color: #000;
      }
    }
  }
  .action {
    .skip {
      border: 0;
      outline: 0;
      background-color: transparent;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      margin-top: 16px;
      color: #000;
    }
    padding-top: 16px;
  }
}
