.faqSection {
  margin-top: 72px;
  padding: 72px 16px;
  background-color: #f9fafb;

  .faqContainer {
    max-width: 768px;
    margin: 0 auto;
    margin-top: 64px;
  }

  .title {
    text-align: center;
  }

  .hint {
    text-align: center;
    color: #667085;
    margin-top: 20px;
    font-size: 20px !important;
  }
}
