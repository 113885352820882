.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    text-align: center;
    span {
      color: #7c8af7;
    }
  }
  .cards {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
