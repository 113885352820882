.reviews {
  margin-top: 72px;
}

.reviewsContent {
  padding: 0 32px;
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 744px) {
    margin-top: 48px;
  }

  .title {
    text-align: center;
    margin-bottom: 24px;

    @media screen and (max-width: 744px) {
      font-size: 27px !important;
    }
  }

  .cards {
    display: flex;
    gap: 32px;
    flex-direction: row;
    justify-content: center;
  }

  .card {
    flex: 0 0 auto;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 16px;
    padding-bottom: 24px;
    width: calc((100% - 64px) / 3);

    .stars {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: #f7c056;
        font-size: 20px;
      }
    }
    .name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;
    }

    .paragraph {
      color: #667085;
      font-size: 16px;
      line-height: 23px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1023px) {
  .reviewsContent {
    .cards {
      gap: 16px;
    }
  }
}

@media screen and (max-width: 743px) {
  .reviewsContent {
    .card {
      width: 100%;
    }
    .cards {
      flex-direction: column;
    }
  }
}
