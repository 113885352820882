.results {
  margin-top: 72px;
}

.resultsContent {
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;

  h2 {
    font-size: 36px !important;
    margin-bottom: 48px;
  }

  .cards {
    display: flex;
    gap: 32px;
    flex-direction: row;
    justify-content: center;
  }

  .card {
    flex: 0 0 auto;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 8px;
    padding-bottom: 24px;
    width: calc((100% - 64px) / 3);
    img {
      width: 100%;
    }
  }
  .name {
    padding: 0 8px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    span {
      color: #f91e1e;
    }
  }
  .text {
    margin-top: 8px;
    padding: 0 8px;
    color: #667085;
    font-size: 16px;
  }
  .readMore {
    color: #7c8af7;
    font-size: 14px;
    font-weight: 500;
    padding: 0 8px;
  }
  .disclaimer {
    margin: 0 auto;
    margin-top: 32px;
    font-size: 14px;
    color: #667085;
    opacity: 0.5;
    text-align: left;
    max-width: 1280px;
  }
}

@media screen and (max-width: 744) {
  .results {
    margin-top: 48px;
  }
}

@media screen and (max-width: 445px) {
  .resultsContent {
    h2 {
      font-size: 27px !important;
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 375px) {
  .results {
    .slide {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .resultsContent {
    .cards {
      gap: 16px;
    }
  }
}

@media screen and (max-width: 743px) {
  .resultsContent {
    .card {
      width: 100%;
    }
    .cardImage {
      .titleBlock {
        font-size: 16px;
      }
      img {
        max-height: none;
      }
    }
    .cards {
      flex-direction: column;
    }
  }
}
