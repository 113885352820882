.workout {
  height: 100%;
  .header {
    padding: 1.6rem 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    h4 {
      flex: 1 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 0.4rem;
    }
    .back {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      img {
        transform: rotate(180deg) scale(1.5);
      }
    }
  }
  .group {
    display: flex;
    flex-direction: column;
    h5 {
      text-align: center;
      font-size: 18px;
      span {
        color: rgba(249, 121, 29, 1);
      }
    }
    .cards {
      max-width: 450px;
      margin: 0 auto;
      padding: 26px 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      gap: 16px;
      .card {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        img {
          border: 1px solid var(--color-area);
          border-radius: 16px;
          width: 100%;
        }
        .cardTitle {
          margin-top: 8px;
          text-align: start;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h5 {
            font-size: 15px;
            font-weight: 600;
          }
          p {
            font-size: 13px;
            font-weight: 500;
            span {
              color: rgba(249, 121, 29, 1);
            }
          }
        }
      }
    }
  }
}
