.container {
  height: 100%;
  margin-right: -20px;
  position: relative;

  h2 {
    text-align: center;
    margin-bottom: 24px;
    z-index: 3;
    position: relative;
  }

  .image {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;

    img {
      width: 100%;
    }
  }
}
