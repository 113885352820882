.container {
  background-image: url(./img/bg.jpg);
  background-size: cover;
  background-position: center center;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;

  h1 {
    line-height: 38px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
  }
  .action {
    padding-top: 16px;
  }
}
