.content {
  padding: 0 16px;
  margin-bottom: 30px;

  .choosePlan {
    h2 {
      text-align: center;
      margin-bottom: 30px;
      span {
        color: #7c8af7;
      }
    }

    .plans {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .agreementContainer {
      margin-bottom: 15px;
      padding: 15px;
    }

    .agreement {
      color: #000;
      opacity: 0.4;
      font-size: 13px;
      text-align: center;
      line-height: 16.9px;
    }
    .control {
      p {
        text-align: center;
        color: #828282;
        margin-top: 24px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .text {
      span {
        font-size: 11px;
        line-height: 22px;
      }
    }
  }
}


.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
