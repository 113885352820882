.trainingDays {
    scrollbar-width: none;
    margin-top: 2.4rem;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    div:first-child {
      margin-left: 2rem;
    }
  }
  .dayItem {
    display: inline-block;
    vertical-align: top;
    min-width: 7.2rem;
    min-height: 4.4rem;
    margin-right: 0.8rem;
    border-radius: 9999rem;
    background-color: #f5f5f5;
    color: #0000003d;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding: 1.2rem 1.6rem;
    text-align: center;
    white-space: normal;
    &.complete {
      background: var(--disabled-button-color);
      color: #fff;
    }
    &.active {
      background: var(--primary);
      color: #fff;
    }
  }