.header {
  padding-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--primary);
  gap: 0.5rem;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
}
.headerCircle {
  width: 16px;
  height: 16px;
}
