.arrowButton {
    width: 80px;
    height: 64px;
    border-radius: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;

    &:disabled {
      opacity: 0.34;
      cursor: not-allowed;
    }
  }