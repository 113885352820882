.tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: left;
  border-radius: 16px;
  padding: 12px;
  animation: fadeInDown 0.3s ease;

  &.info {
    background-color: #eceefe;
  }
  &.warning {
    background-color: #fef5e1;
  }
  &.normal {
    background-color: #def3f0;
  }
  &.attention {
    background-color: #feecec;
  }

  h6 {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
  }
  p {
    font-size: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .content {
    margin-left: 24px;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
