.previewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.preview {
  margin-top: 2.8rem;
  max-width: 650px;
  height: 343px;
  position: relative;
  overflow: hidden;
  border-radius: 1.25rem;
  z-index: 2;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-size: 6.4rem;
    font-weight: 600;
    color: #fff;
  }

  &.completed {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background: linear-gradient(80.89deg, #7c8af7 1.96%, #5105f4 117.2%),
        linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.12),
          rgba(255, 255, 255, 0.12)
        );
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
.startButton {
  margin-top: 2.4rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 3.2rem;
  background: var(--black);
  border-radius: 3.5rem;
  border: 0px;
  color: var(--white);
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  img {
    margin-right: 1rem;
  }
}
