.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  h2 {
    text-align: center;
    font-size: 32px !important;
    font-weight: 700 !important;
  }

  .rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    .ratingWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: bold;
      margin-bottom: 10px;

      span {
        font-size: 28px;
        font-weight: 600;
      }
    }

    .stars {
      span {
        font-size: 18px;
      }
    }

    .ratingText {
      font-size: 18px;
      font-weight: 500;
      margin-top: 5px;
    }
  }

  .imageContainer {
    margin: 20px 0;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
  }
}
