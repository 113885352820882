.workouts {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 32px;
  position: relative;
  width: 100%;
  margin: 0 auto;

  .workoutsSliderWrapper {
    position: relative;
    overflow: hidden;
  }

  .workoutsSliderControls {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 33%;
    transform: translateY(-33%);
    z-index: 3;
  }

  h2 {
    text-align: center;
    font-size: 36px !important;
    font-weight: 600 !important;
    margin-bottom: 36px;
  }

  .cards {
    display: flex;
    gap: 32px;
    flex-direction: row;
    justify-content: center;
    max-width: 1280px;
    margin: 0 auto;
  }

  .card {
    flex: 0 0 auto;
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 8px;
    padding-bottom: 24px;
    width: 33%;
  }

  .cardImage {
    position: relative;
    img {
      border-radius: 8px;
      width: 100%;
      max-height: 338px;
    }
    .titleBlock {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff;
      padding: 10px 20px;
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      font-size: 17px;
      width: calc(100% - 16px);
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      font-weight: 600;
    }
  }

  .description {
    position: relative;
    margin-top: 20px;
    text-align: left;

    p {
      padding: 0 8px;
      color: #667085;
      font-weight: 400;
      line-height: 23px;
      font-size: 16px;
    }
  }

  .disclaimer {
    margin: 0 auto;
    margin-top: 32px;
    font-size: 14px;
    color: #667085;
    opacity: 0.5;
    text-align: left;
    max-width: 1280px;
  }
  .dots {
    bottom: -50px;
    li {
      margin: 0;
      button:before {
        font-size: 9px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .workouts {
    .cardImage {
      .titleBlock {
        font-size: 13px;
      }
    }
    .cards {
      gap: 16px;
    }
  }
}

@media screen and (max-width: 743px) {
  .workouts {
    .card {
      width: 100%;
    }
    .cardImage {
      .titleBlock {
        font-size: 16px;
      }
      img {
        max-height: none;
      }
    }
    .cards {
      flex-direction: column;
    }
  }
}
