.container {
  h2 {
    text-align: center;
  }
  padding-bottom: 24px;
  .cards {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    opacity: 0.5;
  }
}
