.sliderWrapper {
  position: relative;
  overflow: hidden;
}

.sliderControls {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 100px;
  z-index: 3;
}

.control {
  border: 0;
  outline: none;
  background-color: transparent;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.fade {
  display: none;
  position: absolute;
  right: 0px;
  top: 0;
  height: 100%;
  width: 100px;
  z-index: 2;
  background: linear-gradient(to left, #ffffff 0%, transparent 100%);
}

.slider {
  display: flex;
  gap: 32px;
  margin: 0 auto;
  transition: 0.4s ease;
  min-width: 940px;
  max-width: 1280px;
  width: 100%;
}

h2 {
  text-align: center;
  font-size: 36px !important;
  font-weight: 600 !important;
  margin-bottom: 36px;
}



@media screen and (max-width: 1023px) {
  .fade {
    display: block;
  }
  .sliderControls {
    display: flex;
  }
}

@media screen and (max-width: 375px) {
  .slider {
    min-width: auto;
    width: 100%;
  }
  .fade {
    display: none !important;
  }
  .sliderControls {
    padding: 0 16px;
  }
}
