.progressBarContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 20px 0 40px 0;
}

.progressBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding: 0 20px;
}
.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 2px solid #e1e2e3;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  z-index: 1;
  font-size: 9px;
}

.completed {
  background-color: var(--main-pink);
  border: 2px solid var(--main-pink);
}

.current {
  border: 2px solid var(--main-pink);
  background-color: #fff;
}
.progressBarSegment {
  height: 2px;
  background-color: var(--main-pink);
  transition: width 0.3s ease;
  flex-grow: 1;
}

.stepsTitles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    font-weight: 500;
    font-size: 12px;
    span {
      color: #9396a6;
    }
  }
}
