.progressBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar {
  display: flex;
  align-items: center;
}

.stepGroup {
  display: flex;
  align-items: center;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 3px solid #7f73ff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.completed {
  background-color: #7f73ff;
}

.progressBarSegmentContainer {
  width: 45px;
  height: 4px;
  background-color: #f1f1f1;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.progressBarSegment {
  height: 100%;
  background-color: #7f73ff;
  transition: width 0.3s ease-in-out;
}
