.plan {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.4);

  &.checked {
    background-color: var(--main-pink);

    .container .planContent {
      border-color: var(--main-pink);
      background-color: #fff;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    position: relative;

    .discountBadge {
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(--main-pink);
      color: white;
      font-size: 14px;
      font-weight: bold;
      padding: 4px 8px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .planContent {
      padding: 24px 16px 16px 16px;
      border-radius: 16px;
      border: 2px solid #001d671a;
      background-color: #f6f7f9;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #00000099;

        .priceText {
          display: flex;
          margin-left: 40px;
          gap: 12px;
          font-weight: 500;

          .priceNew {
            color: #ff4f64;
          }

          .priceOld {
            color: #9396a6;

            &.isDiscounted {
              text-decoration: line-through;
            }
          }
        }
      }

      .titleRow {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-weight: 500;
          font-size: 16px;
        }

        .icon {
          background-color: rgba(147, 150, 166, 0.08);
          border: 1px solid rgba(147, 150, 166, 0.08);
          height: 32px;
          min-width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }

      .combinedPlan {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .plusSign {
          margin-left: 10px;
        }
      }
    }
  }
}
