.container {
  height: 100%;
  .children {
    max-width: 450px;
    margin: 0 auto;
    height: 100%;
  }
  .header {
    background-color: var(--white);
    width: 100%;
    height: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem;
    padding-bottom: 0;
    p {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      text-align: center;
      color: #000000;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.burger {
  width: 24px;
  height: 18px;
  position: relative;
  z-index: 6;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &.open {
    span {
      background-color: #fff;
    }
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

.burger span:nth-child(1) {
  top: 0px;
}

.burger span:nth-child(2),
.burger span:nth-child(3) {
  top: 6px;
}

.burger span:nth-child(4) {
  top: 12px;
}

.burger.open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

.burger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu {
  z-index: 5;
  display: none;
  transition: opacity 0.4s ease;
  &.open {
    display: flex;
    opacity: 1;
  }

  &.close {
    opacity: 0;
  }
  position: absolute;
  transition: opacity 0.4s ease;
  background-color: #000000e5;
  backdrop-filter: blur(8px);
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  .menuTitle {
    color: #fff;
    font-size: 2rem;
    padding: 2rem;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: auto;
    ul {
      padding: 0;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      display: flex;
      list-style: none;
      li {
        a {
          font-weight: 400 !important;
          font-size: 32px !important;
          color: #ffffff79;
          text-decoration: none;
          cursor: pointer;
          &:active,
          &:hover,
          &.active {
            color: #fff;
          }
        }
      }
    }
  }
}
