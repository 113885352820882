.switch {
  margin: 0 auto;
  margin-top: 24px;
  width: 171px;
  border-radius: 8px;
  background-color: var(--card-bg);
  padding: 1px;
  display: flex;
  .item {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    &.active {
      box-shadow: 0px 3px 1px 0px #0000000a;

      box-shadow: 0px 3px 8px 0px #0000001f;

      border: 0.5px solid #0000000a;
      background-color: #fff;
      border-radius: 6px;
    }
  }
}
