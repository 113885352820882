.moneyBack {
  background-color: var(--card-bg);
  border-radius: 16px;
  padding: 30px 20px;
  margin: 16px;

  .icon {
    text-align: center;
  }

  .title {
    text-align: center;
    margin-top: 12px;
  }

  .paragraph {
    margin-top: 20px;
    font-size: 15px;
    line-height: 19.5px;
    font-weight: 400;
    a {
      color: #7c8af7;
      font-weight: 600;
    }
  }
}
