.layout {
  max-width: 480px;
  margin: 0 auto;
  padding: 10px 16px 10px 16px;

  .note {
    font-size: 13px;
    color: #9396a6;
    line-height: 16.9px;
  }
}
