.header {
  background-color: #f7f6fa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 196px;

  h1 {
    font-size: 40px;
    font-weight: 600;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 58px;
  img:nth-child(2) {
    margin-left: 3px;
  }
  .breadcrumbsTitle {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 300;
  }
}

.container {
  max-width: 958px;
  margin: 0 auto;
  padding: 0 8px 40px 8px;
}

.title {
  margin-top: 32px;
  h2 {
    font-size: 28px;
    font-weight: 500;
  }
  p {
    margin-top: 4px;
    font-size: 16px;
  }
}

.content {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }
}

.item {
  h2 {
    margin-bottom: 6px;
  }
  h3 {
    margin-top: 10px;
  }
  p {
    margin-top: 10px;
    &.marginLeft {
      margin-left: 10px;
    }
    &.underline {
      text-decoration: underline;
    }
  }

  a {
    color: #000;
  }
}

ol {
  padding: 0;

  .title {
    font-weight: 500;
    font-size: 24px;
  }

  li {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;

    h4 {
      margin: 10px 0;
      font-weight: 700;
    }
    > p {
      margin-top: 10px;
    }
    a {
      color: #000;
    }
  }

  p {
    font-size: 14px;
    &.marginLeft {
      margin-left: 10px;
    }
  }
}

ul {
  list-style-type: disc;
  margin-left: 20px;

  li {
    display: list-item;
    margin: 0;
    font-size: 14px;
  }
}

.numberList {
  margin: 10px 0 0 30px;

  li {
    counter-increment: item;
    margin: 0;
    &::before {
      content: counter(item) ". ";
      font-weight: 400;
    }
  }
}

.table {
  margin: 10px 0;
  font-size: 12px;
  border-collapse: collapse;
  caption {
    caption-side: top;
    text-align: left;
    font-weight: 700;
    color: #000;
    font-size: 14px;
  }
  ul {
    margin: 0;
  }
  p,
  li {
    font-size: 14px;
  }

  td {
    padding: 10px;
    border: 1px solid #ddd;
    &.underline {
      text-decoration: underline;
    }
  }

  th {
    border: 1px solid #ddd;
    background: #f3f5f7;
    padding: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 380px) {
  .table {
    font-size: 10px;
    p,
    li {
      font-size: 10px;
    }
  }
}
