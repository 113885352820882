.cards {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 192px;
  margin-top: 16px;

  .card {
    position: relative;
    z-index: 3;
    background-color: var(--card-bg);
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    &:active {
      background-color: var(--primary-hover);
    }
  }
}

@media screen and (min-width: 430px) {
  .cards {
    .card {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
