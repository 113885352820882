.footer {
  margin-top: 72px;

  .content {
    max-width: 1280px;

    margin: 0 auto;
    padding: 0 32px;

    .cta {
      text-align: center;
      margin-bottom: 64px;

      h2 {
        font-weight: 600;
        margin-bottom: 16px;
        line-height: 1.2;
      }

      p {
        font-size: 20px;
        color: #667085;
      }

      .getStarted {
        margin-top: 64px;
        background: #000;
        color: #fff;
        border: none;
        padding: 17px 40px;
        line-height: 24px;
        border-radius: 100px;
        font-weight: 600;
        font-size: 15px !important;
        letter-spacing: -0.64px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background: #333;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      border-top: 1px solid #e5e7eb;
      margin-bottom: 40px;

      .copyright {
        color: #98a2b3;
        font-size: 14px;
      }

      .links {
        display: flex;
        gap: 24px;

        a {
          color: #98a2b3;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}

@media (max-width: 744px) {
  .footer {
    margin-top: 48px;
  }
}
@media (max-width: 743px) {
  .footer {
    margin-top: 48px;
    .content {
      .bottom {
       flex-direction: column-reverse;
       align-items: flex-start;
       row-gap: 16px;
      }
    }
  }
}

