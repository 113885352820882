.additionalDiscount {
  padding: 10px 8px 0 8px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;

  .previousDiscount {
    font-size: 16px;
    color: #808080;
    text-align: center;
    margin: 8px auto;
    font-weight: 50;
    text-decoration: line-through;

    .oldDiscount {
      color: #058d66;
    }
  }

  .discountOffer {
    display: flex;
    align-items: center;
    background-color: #e8f5f2;
    padding: 16px;
    gap: 10px;
    border-radius: 16px;
  }

  .icon {
    background-color: white;
    height: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .offerText {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;

    .newDiscount {
      color: #058d66;
    }
  }
}
