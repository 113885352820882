.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  border-radius: 16px;
  padding: 24px;
  background-color: #f0f2f5;
  &.low {
    background-color: #f5e7e7;
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    font-size: 13px;
  }
}
