.container {
  h2 {
    text-align: center;
  }

  .image {
    margin-top: 24px;
    width: 100%;
    border-radius: 16px;
  }

  .cards {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    .card {
      background-color: var(--card-bg);
      padding-left: 16px;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .container {
    .cards {
      .card {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
