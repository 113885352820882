.activate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.code {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 22px;
  color: #9396a6;
  strong {
    color: black;
  }
}

.emailButton {
  margin-top: 8px;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #7c8af7;
  text-decoration: underline;
  font-size: 16px;
}

.codeInput {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.codeInputItem {
  font-size: 18px;
  border: 1px solid #9396a633;
  border-radius: 16px;
  background-color: #f8f8f8;
  width: 100%;
  padding: 22px 16px;
  text-align: center;
}

.actions {
  margin-top: 48px;
  width: 100%;
}

.resend {
  margin-top: 16px;
  color: black;
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
}

.footer {
  color: #9396a6;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  a {
    color: #7c8af7;
  }
}

.email {
  display: flex;
  flex-direction: column;
}

.emailTitle {
  margin-top: 28px;
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.input {
  margin-top: 24px;
  position: relative;
  div {
    border: 1px solid #9396a633;
  }
  label {
    margin-bottom: 8px;
    font-size: 14px;
    color: #9396a6;
    font-weight: 600;
  }
  input {
    text-align: left;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  a {
    margin-top: 24px;
    font-weight: 600;
    font-size: 13px;
    color: var(--main-pink);
    text-decoration: none;
    text-align: center;
  }
}
