    .review {
        margin: 16px 0;
        background-color: var(--card-bg);
        border-radius: 16px;
        padding: 24px 20px;
        .stars {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .name {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 12px 0;
          font-size: 14px;
          p {
            font-weight: 700;
          }
        }
        .title {
          p {
            font-weight: 600;
          }
        }
    
        .paragraph {
          margin-top: 12px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    
      .dots {
        li {
          margin: 0 -2px;
          margin-bottom: 16px;
          button:before {
            font-size: 9px;
          }
        }
      }
  