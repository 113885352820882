@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "IBM Plex Sans", sans-serif !important;

  --white: #ffffff;
  --black: #000000;
  --color-button: #f6f3ed;
  --color-area: #efede8;
  --button-image--border: #d9d9d9;
  --button-image--background: rgba(67, 106, 97, 0.06);
  --disabled-button-color: #7c8af77c;

  --main-pink: rgba(124, 138, 247, 1);
  --light-pink: #ffdee5;
  --gray-bg: #f2f2f2;
  --primary-text: #000;
  --secondary-text: #828282;

  --card-bg: #f8f8f8;

  --primary: #7c8af7;
  --primary-hover: #d4d9fc;

  --gray-disabled: #eaeaec;

  overflow: auto;
  height: auto;
  width: 100%;
  --start-page--background: #dbc3bf;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1 {
  font-size: 36px !important;
  font-weight: 700 !important;
}
h2 {
  font-size: 27px !important;
  font-weight: 600 !important;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

h3 {
  font-size: 22px !important;
  font-weight: 700;
}
h5 {
  font-size: 14px;
  font-weight: 700;
}

p {
  font-size: 14px;
}

video:focus {
  outline: 0;
  outline-offset: 0;
}

button:focus {
  outline: 0;
  outline-offset: 0;
}

body p {
  margin: 0;
  padding: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.scroll-lock {
  overflow: hidden !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

