.container {
  .progress {
    width: 50%;
    margin: 0 auto;
  }

  .subtitle {
    margin: 24px 0;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .reviewContainer {
    padding: 24px 0;
    border-top: 1px solid #d9d9d9;

    .people {
      text-align: center;

      h2 {
        font-size: 36px !important;
        font-weight: 700 !important;
        margin-bottom: 16px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
