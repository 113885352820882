.step {
  height: 100%;
  // animation: fadein 0.8s ease;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
