.container {
  text-align: center;
  .quiz {
    font-weight: 600;
    margin-top: 16px;
    font-size: 12px;
  }
  .choose {
    color: rgba($color: #000000, $alpha: 0.5);
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
  }
  .card {
    background-color: var(--card-bg);
    border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
    }
    &:active {
      background-color: var(--primary-hover);
    }
    .label {
      background-color: var(--primary);
      color: #fff;
      padding: 10px 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .privacy {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    a {
      color: var(--primary);
    }
  }
}
