.header {
  text-align: center;
  margin-bottom: 16px;

  h2 {
    font-size: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    color: #9396a6;
  }
}
