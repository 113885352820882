.modalOverlay {
  padding: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  width: 100%;
  max-width: 400px;
  height: 360px;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  .logo {
    height: 96px;
    width: 96px;
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 20px 0;
    text-align: center;
  }
  p {
    font-size: 16px;
    color: #7D7D7D;
    text-align: center;
  }
  ol {
    margin-top: 16px;
    li {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
    }
  }
}

.close {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-220px);
  right: 4px;
  border: none;
  cursor: pointer;
  z-index: 2;
}


